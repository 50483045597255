import { useNavigate } from "react-router-dom"
import DataContext from "../../Components/Element/context"
import Footer from "../../Components/Footer"
import Header from "../../Components/Header"
import { ApiService } from "../../Components/Services/apiservices"
import React, { useState, useEffect, useRef, useContext } from "react"
import usePlacesAutocomplete, { getDetails, getGeocode, getLatLng } from "use-places-autocomplete";
import constant from "../../Components/Services/constant"
import Autocomplete from "react-google-autocomplete";



const Profile = () => {
    const [userDetail, setUserdetail] = useState({})
    const session_token = localStorage.getItem('USER_TOKEN')
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const contextValues = useContext(DataContext)
    const [showaddressdropdown, setshowaddressdropdown] = useState(false);
    const [cityDropdown, setcityDropdown] = useState([])
    const [areaDropdown, setareaDropdown] = useState([])
    const [stateDropdown, setstateDropdown] = useState([])
    const didMountRef = useRef(true)
    const navigate = useNavigate()
    useEffect(() => {
        if (didMountRef.current) {
            getstatedropdown()
            getcitydropdown()
            const session_token = localStorage.getItem('USER_TOKEN')
            if (session_token && session_token !== null) {
                // getUserdata()
            }

        }
        didMountRef.current = false
    }, [])
    const onTodoChange = (e) => {
        const { name, value } = e.target
        setUserdetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    };

    const getstatedropdown = () => {
        ApiService.fetchData('getallStates').then((res) => {
            if (res.status == 'success') {
                setstateDropdown(res.data)
            }
        })
    }
    const getcitydropdown = (stateId) => {
        const dataString = {
            state_id: stateId
        }
        ApiService.postData('getCityByState', dataString).then((res) => {
            if (res) {
                setcityDropdown(res)
            }
        })
    }
    const getareadropdown = (cityId) => {

        const dataString = {
            pin_city_id: cityId
        }
        ApiService.postData('getAreaByCity', dataString).then((res) => {
            if (res) {
                setareaDropdown(res)
            }
        })
    }

    const getUserdata = () => {

        ApiService.fetchData('getuserdata').then((res) => {
            if (res.status == 'success') {
                setUserdetail(res.rowUserData)
                setValue(res.rowUserData.user_address)
                contextValues.setUserData(res.rowUserData)

            }
            else if (res.status == 'session_expired' && res.message == 'Session expired') {
                localStorage.removeItem('USER_TOKEN')
                navigate('/')
            }

        }).catch((error) => {

        })
    }
    const updateProfile = (e) => {
        let counter = 0;
        const myElements = document.getElementsByClassName('profilerequired');
        for (let i = 0; i < myElements.length; i++) {
            if (myElements[i].value === '') {
                myElements[i].style.border = '1px solid red';
                counter++;
            } else {
                myElements[i].style.border = '';
            }
        }
        if (counter == 0) {
            setIsLoading(true)
            const updatedUserDetail = {
                ...userDetail,
                user_logo: userDetail.user_logo.value || '',
                user_banner_image: userDetail.user_banner_image.value || ''// Include user_logo if it's available
            };
            ApiService.postData("updateProfile", updatedUserDetail).then((res) => {
                if (res.status === "success") {
                    setSuccessMessage(res.message)
                    setTimeout(() => {
                        setSuccessMessage('')
                        window.location.reload()
                    }, 1000);
                }
                else if (res.status == 'error' && res.message == 'Session expired') {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        localStorage.removeItem("USER_TOKEN");
                        window.location.href = '/';
                    }, 500);
                }
                else {
                    setErrorMessage(res.message);
                    setTimeout(() => {
                        setIsLoading(false)
                    }, 500);
                }
            }).catch(() => {
                setIsLoading(false)
            });
        }

    }

    const handleImageUpload = (event) => {
        setErrorMessage('');
        let name = event.target.name
        const file = event.target.files[0];
        if (file && (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg")) {
            if (file.size > 2 * 1024 * 1024) {
                setErrorMessage('File size should not exceed 2MB');
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                setUserdetail((prevState) => ({
                    ...prevState,
                    [name]: { type: 'base64', value: base64String },
                }));
            };
            reader.readAsDataURL(file);
        } else {
            setErrorMessage('Please select an image file (jpg, jpeg, png)');
        }
    };

    const {
        suggestions: { status, data },
        setValue,
        value,
        clearSuggestions,
    } = usePlacesAutocomplete({
        requestOptions: {
            componentRestrictions: { country: 'IN' },
        },
        debounce: 10,
    });

    const handleInput = (e) => {
        setErrorMessage('');
        setValue(e.target.value);
        setshowaddressdropdown(true)
    };
   
    // const handleSelect = async (placeDetails) => {
    //     setErrorMessage('');
       

    //     try {
    //         const addressComponents = placeDetails.address_components;
    //         const formattedAddress = placeDetails.formatted_address;
            
    //         // Extract address components
    //         const cityComponent = addressComponents.find(component =>
    //             component.types.includes("locality") ||
    //             component.types.includes("administrative_area_level_2")
    //         );
    //         const stateComponent = addressComponents.find(component =>
    //             component.types.includes("administrative_area_level_1")
    //         );
    //         const countryComponent = addressComponents.find(component =>
    //             component.types.includes("country")
    //         );
    //         const postalComponent = addressComponents.find(component =>
    //             component.types.includes("postal_code")
    //         );
    //         const areaComponent = addressComponents.find(component =>
    //             component.types.includes("sublocality_level_1")
    //         );
    
    //         const cityName = cityComponent ? cityComponent.long_name : '';
    //         const stateName = stateComponent ? stateComponent.long_name : '';
    //         const country = countryComponent ? countryComponent.long_name : '';
    //         const postalCode = postalComponent ? postalComponent.long_name : '';
    //         const area = areaComponent ? areaComponent.long_name : '';

    //         console.log(cityName, stateName, area , postalCode)

    //    console.log( stateDropdown ,'stateDropdown')
    //    if (!stateDropdown.length) {
    //     alert('State dropdown data is not available.');
    //     return;
    // }
    //         const filteredState = stateDropdown.find(
    //             (state) => state.state_name === stateName
    //         );
    //         console.log(filteredState , 'filteredState')
    //         const filteredCity = cityDropdown.find(
    //             (city) => city.cities_name === cityName
    //         );
    //         const filteredArea = areaDropdown.find(
    //             (areaObj) => areaObj.PostOfficeName === area
    //         );

    //         // Update user details state with found values
    //         setUserdetail((prevState) => ({
    //             ...prevState,
    //             user_state: filteredState?.state_id || '',
    //             user_city: filteredCity?.cities_id || '',
    //             user_address: formattedAddress || '',
    //             user_pincode: postalCode || '',
    //             user_short_address: filteredArea?.pin_id || '',
    //         }));

    //         // Fetch city and area dropdowns based on the selections
    //         if (filteredState?.state_id) {
    //             await getcitydropdown(filteredState.state_id);
    //         }
    //         if (filteredCity?.cities_id) {
    //             await getareadropdown(filteredCity.cities_id);
    //         }
    
    //         // Check if we have enough data
           
    //     } catch (error) {
    //         console.error('Error fetching place details:', error);
    //         setErrorMessage('An error occurred while fetching place details.');
    //     }
    // };
    console.log(stateDropdown , 'satedrop')
    const handleSelect = async (placeDetails) => {
        setErrorMessage('');
    
        try {
            const { address_components: addressComponents, formatted_address: formattedAddress } = placeDetails;
            
            // Extract address components
            const cityComponent = addressComponents.find(component =>
                component.types.includes("locality") ||
                component.types.includes("administrative_area_level_2")
            );
            const stateComponent = addressComponents.find(component =>
                component.types.includes("administrative_area_level_1")
            );
            const countryComponent = addressComponents.find(component =>
                component.types.includes("country")
            );
            const postalComponent = addressComponents.find(component =>
                component.types.includes("postal_code")
            );
            const areaComponent = addressComponents.find(component =>
                component.types.includes("sublocality_level_1")
            );
    
            const cityName = cityComponent?.long_name || '';
            const stateName = stateComponent?.long_name || '';
            const country = countryComponent?.long_name || '';
            const postalCode = postalComponent?.long_name || '';
            const area = areaComponent?.long_name || '';
    
            console.log(cityName, stateName, area, postalCode);
            console.log(stateDropdown, 'stateDropdown');
    
            // if (!stateDropdown.length) {
            //     alert('State dropdown data is not available.');
            //     return;
            // }

            var filteredState

            ApiService.fetchData('getallStates').then((res) => {
                if (res.status == 'success') {
                    filteredState = stateDropdown.find(
                        (state) => state.state_name === stateName
                    );

                    return filteredState
                }
            })

            console.log(filteredState , 'filterState')
    
            // const filteredState = stateDropdown.find(
            //     (state) => state.state_name === stateName
            // );
            console.log(filteredState, 'filteredState');
    
            const filteredCity = cityDropdown.find(
                (city) => city.cities_name === cityName
            );
            const filteredArea = areaDropdown.find(
                (areaObj) => areaObj.PostOfficeName === area
            );
    
            // Update user details state with found values
            setUserdetail((prevState) => ({
                ...prevState,
                user_state: filteredState?.state_id || '',
                user_city: filteredCity?.cities_id || '',
                user_address: formattedAddress || '',
                user_pincode: postalCode || '',
                user_short_address: filteredArea?.pin_id || '',
            }));
    
            // Fetch city and area dropdowns based on the selections
            if (filteredState?.state_id) {
                await getcitydropdown(filteredState.state_id);
            }
    
            if (filteredCity?.cities_id) {
                await getareadropdown(filteredCity.cities_id);
            }
        } catch (error) {
            console.error('Error fetching place details:', error);
            setErrorMessage('An error occurred while fetching place details.');
        }
    };
    
    const renderSuggestions = () =>
        data.map((suggestion) => {
            const {
                place_id,
                description,
                structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
                <li key={place_id} onClick={() => { handleSelect(suggestion) }} className="d-flex mt-0 border-bottom p-2" ><i className="ri-map-pin-line me-2 "></i><div>
                    <h6 style={{ fontSize: '18px' }}>{main_text}</h6> <p className='mb-0 tx-12 tx-gray'>{secondary_text}</p></div></li>
            );
        });




    return (<>
        <div className="layout-wrap">
            <Header></Header>
            <div className="main-content">
                <div className="main-content-inner">
                    <div className="wrapper-content row">
                        <div className="col-xl-12">
                            <div className="widget-box-2 wd-listing">
                                <h6 className="title">My Profile</h6>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col-6">
                                        <div className="box">
                                            <h6 className="title">Avatar</h6>
                                            <div className="box-agent-avt">
                                                {userDetail?.user_logo && userDetail?.user_logo?.type == 'base64' ? <>
                                                    <div className="avatar">
                                                        <img src={`data:image/jpeg;base64,${userDetail?.user_logo?.value}`} alt="avatar" loading="lazy" width="128" height="128" />
                                                    </div>

                                                </> : <div className="avatar">
                                                    <img src={userDetail?.user_logo ? userDetail?.user_logo : 'images/avatar/account.jpg'} alt="avatar" loading="lazy" width="128" height="128" />
                                                </div>}

                                                <div className="content uploadfile">
                                                    <p>Upload a new avatar</p>
                                                    <div className="box-ip ">
                                                        <input type="file" className="ip-file" name='user_logo' accept="image/png,image/jpeg" onChange={(e) => { handleImageUpload(e) }} />
                                                    </div>
                                                    <p>Max size 2 MB</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-6">
                                        <div className="box">
                                            <h6 className="title">Banner Image</h6>
                                            <div className="box-agent-avt">
                                                {userDetail?.user_banner_image && userDetail?.user_banner_image?.type == 'base64' ? <>
                                                    <div className="avatar">
                                                        <img src={`data:image/jpeg;base64,${userDetail?.user_banner_image?.value}`} alt="avatar" loading="lazy" width="128" height="128" />
                                                    </div>

                                                </> : <div className="avatar">
                                                    <img src={userDetail?.user_banner_image ? userDetail?.user_banner_image : constant.DEFAULT_IMAGE} alt="avatar" loading="lazy" width="128" height="128" />
                                                </div>}

                                                <div className="content uploadfile">
                                                    <p>Upload banner image</p>
                                                    <div className="box-ip">
                                                        <input type="file" className="ip-file" name='user_banner_image' accept="image/png,image/jpeg" onChange={(e) => { handleImageUpload(e) }} />
                                                    </div>
                                                    <p>Max size 2 MB</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <h6 className="title">Information</h6>

                                <div className="row " style={{ marginBottom: '20px' }}>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Full name:<span>*</span></label>
                                            <input type="text" className="form-control style-1 profilerequired" name='user_name' value={userDetail.user_name} onChange={(e) => { onTodoChange(e) }} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset ">
                                            <label for="name">Designation:<span>*</span></label>
                                            <input type="text" className="form-control style-1 profilerequired" name='user_designation' value={userDetail.user_designation} onChange={(e) => { onTodoChange(e) }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="row " style={{ marginBottom: '20px' }}>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Email:</label>
                                            <input type="text" value={userDetail.user_email} disabled className="form-control style-1" />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Mobile:</label>
                                            <input type="text" value={userDetail.user_mobile} disabled className="form-control style-1" />
                                        </div>

                                    </div>
                                </div>
                                <h6 className="title">Social links</h6>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Facebook:</label>
                                            <input type="text" className="form-control style-1 " name='user_facebook' value={userDetail.user_facebook} onChange={(e) => { onTodoChange(e) }} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Linkedin:</label>
                                            <input type="text" className="form-control style-1" name='user_linkedin' value={userDetail.user_linkedin} onChange={(e) => { onTodoChange(e) }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">X (Twitter):</label>
                                            <input type="text" className="form-control style-1" name='user_twitter' value={userDetail.user_twitter} onChange={(e) => { onTodoChange(e) }} />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Instagram:</label>
                                            <input type="text" className="form-control style-1" name='user_instagram' value={userDetail.user_instagram} onChange={(e) => { onTodoChange(e) }} />
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label for="name">Website Url:</label>
                                            <input type="text" className="form-control style-1" name='user_website_url' value={userDetail.user_website_url} onChange={(e) => { onTodoChange(e) }} />
                                        </div>
                                    </div>

                                </div>
                                <div className="box box-fieldset">
                                    <label for="name">Bio:</label>
                                    <textarea type="text" className="form-control style-1" rows={3} name='user_bio' value={userDetail.user_bio} onChange={(e) => { onTodoChange(e) }} />
                                </div>
                                <h6 className="title">Address</h6>
                                <div className="box box-fieldset">
                                    <label >
                                        Full Address:<span>*</span>
                                    </label>
                                    {/* <input type="text" className="form-control style-1" placeholder="Enter property full address" name='user_address' value={value}
                                        onChange={handleInput} autoComplete="new-password" />
                                    {status === "OK" && showaddressdropdown && <ul className="border mt-10 rounded">{renderSuggestions()}</ul>} */}
                                </div>
                                <Autocomplete
                                    apiKey="AIzaSyAE7oxhbgUxiIzikhCeN6TB6UUe1frQnZs"
                                    className="form-control style-1" placeholder="Enter full address" name='user_address'
                                    autoComplete="new-password"
                                    types={["establishment"]}
                                    onPlaceSelected={(place) => {
                                        handleSelect(place)
                                        // console.log(place);
                                    }}
                                    options={{
                                        types: ["establishment"], // or ["geocode"] for address searches
                                        componentRestrictions: { country: "in" } // Restrict to India
                                    }}
                                />
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label >
                                                Country:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='user_country_code'
                                                disabled={true}
                                                value={userDetail.user_country_code || ''}
                                                onChange={(e) => { onTodoChange(e) }}
                                            >
                                                <option value="91">India</option>


                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label >
                                                Province/State:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='user_state'
                                                value={userDetail.user_state || ''}
                                                onChange={(e) => { onTodoChange(e) }}
                                            >
                                                <option value="">Select State</option>

                                                {stateDropdown &&
                                                    stateDropdown.map((item, index) => (
                                                        <option value={item.state_id} key={index}>
                                                            {item.state_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label >
                                                City:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='user_city'
                                                value={userDetail.user_city || ''}
                                                onChange={(e) => { onTodoChange(e) }}
                                            >
                                                <option value="">Select City</option>

                                                {cityDropdown &&
                                                    cityDropdown.map((item, index) => (
                                                        <option value={item.cities_id} key={index}>
                                                            {item.cities_name}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label >
                                                Neighborhood/ Area:<span>*</span>
                                            </label>
                                            <select
                                                className="nice-select"
                                                name='user_short_address'
                                                value={userDetail.user_short_address || ''}
                                                onChange={(e) => { onTodoChange(e) }}
                                            >
                                                <option value="">Select Area</option>

                                                {areaDropdown &&
                                                    areaDropdown.map((item, index) => (
                                                        <option value={item.pin_id} key={index}>
                                                            {item.PostOfficeName}
                                                        </option>
                                                    ))}
                                            </select>
                                        </div>

                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <div className="box box-fieldset">
                                            <label >
                                                Zip Code:<span>*</span>
                                            </label>
                                            <input type="text" className="form-control style-1" name="user_pincode" placeholder="Enter property zip code" value={userDetail.user_pincode} onChange={(e) => { onTodoChange(e) }} />
                                        </div>
                                    </div>

                                </div>

                                <div className="box " style={{ marginTop: '50px' }}>
                                    <a href="javascript:void(0)" className="tf-btn primary" onClick={(e) => { updateProfile(e) }}>Update Profile</a>
                                </div>

                                {successMessage && (
                                    <div className="alert alert-success" role="alert">
                                        {successMessage}
                                    </div>
                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    </>)
}

export default Profile